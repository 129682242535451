import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const AuthRoutes = ({ children }) => {
  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies(["access_token"]);
  let auth = { token: Object.keys(cookies).length > 0 ? true : false };

  if (auth.token == false) {
    navigate("/");
  } else {
    return children;
  }
};

export default AuthRoutes;
