import React, { useState } from "react";
import { useCookies } from "react-cookie";
import useUserStore from "../store/userStore";
import toast from "react-hot-toast";
// import { useNavigation } from 'react-router-dom';
import api from "../store/api";

function LogIn() {
  
  const login = useUserStore((state) => state.login);
  const updateUser = useUserStore((state) => state.updateUser);
  const { user } = useUserStore();
  const [cookies, setCookie] = useCookies(["access_token"]);
  const [fixerUserName, setFixerUserName] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fixerOtp, setFixerOtp] = useState("");
  const [getOtp, setGetOtp] = useState(false);

  const handleFixerUserName = (e) => {
    setFixerUserName(e.target.value);
  };
  const handleRegisterEmail = (e) => {
    setRegisterEmail(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleFixerOtp = (e) => {
    setFixerOtp(e.target.value);
  };
  const handleGetOtp = async () => {
    try {
      const response = await api.post("users/loginfixer", {
        email: registerEmail,
        password: password
      });
  
      if (response.status === 200) {
        toast.success("Logged in Successfully!");
        setCookie("access_token", response.data.access_token);
        localStorage.setItem("isLoggedIn", response.data.access_token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        login();
        updateUser(response.data.user);
        window.location.href = "/dashboard";
      } else if (response.status === 409) {
        toast.error("Please check username or password!");
      } else {
        toast.error("An unexpected error occurred. Please try again later.");
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    }
  };
  

  const handleVerifyOtp = async () => {
    try {
      // Make the API call
      const response = await api.post(`users/verifyFixerOtp/${user?._id}`, {
        otp: fixerOtp,
      });

      // Handle response
      if (response.status === 200) {
        toast.success("Verified Successfully!");
        window.location.href = "/dashboard";
      } else {
        toast.error("Verification failed. Please try again.");
      }
    } catch (error) {
      // Handle specific error cases if needed
      if (error.response) {
        // Server responded with a status other than 200 range
        console.error("Error response:", error.response);
        toast.error(
          error.response.data.message ||
            "An error occurred during verification."
        );
      } else if (error.request) {
        // Request was made but no response received
        console.error("Error request:", error.request);
        toast.error("No response from server. Please try again.");
      } else {
        // Something else caused the error
        console.error("Error message:", error.message);
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  return (
    <section className="login-section">
      <div className="container">
        <div className="login-main-box flex-box space-between align-center">
          <div className="login-left-box">
            <h2>FXRY LogIn</h2>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. In
              repellendus debitis, autem non dolorem, adipisci ea ab hic
              placeat, quam quod. Quaerat, atque! Labore, cupiditate?
            </p>
          </div>
          <div className="login-right-box">
            <h1>FXRY FIXERS/TAILORS</h1>
            <div className={`form-box ${getOtp ? "hide-form-box" : ""}`}>
              <div className="feild-box flex-box">
                {/* <input className='login-input' value={fixerUserName} onChange={handleFixerUserName} type="text" placeholder="User Name" /> */}
                {/* <input className='login-input' value={registerEmail} onChange={handleRegisterEmail} type="email" placeholder="Your Register Email" /> */}
                <h4>Your Registered Username</h4>
                <input
                  className="login-input"
                  value={registerEmail}
                  onChange={handleRegisterEmail}
                  type="text"
                  placeholder="e.g. shkain01"
                />
                <h4>Password</h4>
                <input
                  className="login-input"
                  value={password}
                  onChange={handlePassword}
                  type="password"
                  placeholder="Password"
                />
              </div>
              <button onClick={handleGetOtp} className="save-info-btn">
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LogIn;
