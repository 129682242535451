import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Switch } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
import './assets/css/App.css'
import DashBoard from './pages/DashBoard';
import JobDetail from './pages/JobDetail';
import CommonPage from './pages/CommonPage';
import LogIn from './pages/LogIn';
import AuthRoutes from './store/auth';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
const queryClient = new QueryClient();
function App() { 
  return (
    <QueryClientProvider client={queryClient}>
        <Router>
            <Toaster />
              <Routes>
                <Route path='/cancelbyadmin' element={<AuthRoutes><DashBoard/></AuthRoutes>} />
                <Route path='/dashboard' element={<AuthRoutes><DashBoard/></AuthRoutes>} />
                <Route path='/onhold' element={<AuthRoutes><DashBoard/></AuthRoutes>} />
                <Route path='/needmore' element={<AuthRoutes><DashBoard/></AuthRoutes>} />
                <Route path='/fixed' element={<AuthRoutes><DashBoard/></AuthRoutes>} />
                <Route path='/fixednotdropped' element={<AuthRoutes><DashBoard/></AuthRoutes>} />
                <Route path='/inprogress' element={<AuthRoutes><DashBoard/></AuthRoutes>} />
                <Route path='/itempicked' element={<AuthRoutes><DashBoard/></AuthRoutes>} />
                <Route path='/assigned' element={<AuthRoutes><DashBoard/></AuthRoutes>} />
                <Route exact path='/:status/jobs/:jobId' element={<JobDetail/>} />
                <Route path='/credited' element={<AuthRoutes><CommonPage/></AuthRoutes>} />
                <Route path='/pending' element={<AuthRoutes><CommonPage/></AuthRoutes>} /> 
                <Route path='/' element={<LogIn/>} /> 
              </Routes>
              <Toaster />
        </Router>
    </QueryClientProvider>
  );
}

export default App;
