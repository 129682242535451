import React from 'react';
import { useLocation } from 'react-router-dom';
import MenuBox from '../components/common/MenuBox';
import AllJobTab from '../components/module-tabs/AllJobTab';
import AllCreditsTab from '../components/module-tabs/AllCreditsTab';
import useUserStore from '../store/userStore';
import api from '../store/api';
import { useQuery } from 'react-query';

function CommonPage() {
  const location = useLocation();
  const pathSegments = location.pathname.substring(1);
  return (
    <section className='main-dashboard-section'>
      <div className="container">
        <div className="main-dashboard-box flex-box space-between">
          <MenuBox />
          {pathSegments === 'assigned' || pathSegments === 'itempicked' || pathSegments === 'inprogress' || pathSegments === 'fixednotdropped' || pathSegments === 'fixed' || pathSegments === 'needmore' || pathSegments === 'onhold' || pathSegments === 'cancelbyadmin'  ? (
            <AllJobTab/>
          ) : pathSegments === 'credited' ? (
            <AllCreditsTab />
          ) : pathSegments === 'pending'  ? (
            <AllCreditsTab/>
          ) : (
            <h1>Module Not Found</h1>
          )}
        </div>
      </div>
    </section>
  );
}

export default CommonPage;
