import React from 'react'
import { useLocation, useParams } from 'react-router-dom';
import MenuBox from '../components/common/MenuBox';
import JobDetailTab from '../components/module-tabs-detail/JobDetailTab';
import api from '../store/api';
import { useQuery } from 'react-query';

function JobDetail() {
  const location = useLocation();
  const param = useParams()
  const jobDetails = location.state || JSON.parse(sessionStorage.getItem('currentJob')) || {};

  const fetchSingleOrderDetails = async () => {
    const response = await api.get(`order/getjobwithorder/${param.jobId}`)
    return response.data[0];
  }

  const {
    data, isLoading, refetch
  } = useQuery({
    queryKey: ["fetchSingleOrderDetails"],
    queryFn: fetchSingleOrderDetails
  })

  
  return (
    <section className='main-dashboard-section' >
      <div className="container">
        <div className="main-dashboard-box flex-box space-between">
          <MenuBox />
          {!isLoading &&
            <JobDetailTab jobId={data?._id} taskName={data?.item_details?.name}
              pickUpDate={data[0]?.orders[0]?.collection_date}
              dropOffDate={jobDetails?.dropOffDate}
              order={data}
              refetch={refetch}
            />
          }
        </div>
      </div>
    </section>
  )
}

export default JobDetail