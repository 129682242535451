import React from "react";
import jobInfoIcon from "../../assets/img/job-info-icon.webp";

const calculatePickupDate = (deliveryDate) => {
  const deliveryDay = new Date(deliveryDate).getDay(); // 0 represents Sunday, 1 represents Monday, 3 represents Wednesday, 5 represents Friday
  let pickupOffset = 0;

  // Determine the initial offset based on the delivery day
  switch (deliveryDay) {
      case 1: // Monday
          pickupOffset = 10;
          break;
      case 3: // Wednesday
          pickupOffset = 12;
          break;
      case 5: // Friday
          pickupOffset = 10;
          break;
      default:
          return null; // Handle other days if necessary
  }

  const pickupDate = new Date(deliveryDate);

  // Add offset to delivery date, skipping Saturdays and Sundays
  while (pickupOffset > 0) {
      pickupDate.setDate(pickupDate.getDate() + 1);
      const dayOfWeek = pickupDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Skip Sundays (0) and Saturdays (6)
          pickupOffset--;
      }
  }

  return pickupDate.toISOString(); // Convert to ISO format for consistency
};

function JobInfo({
  service,
  jobId,
  SKU,
  taskName,
  pickUpDate,
  dropOffDate,
  createdAt,
  collectionDate,
}) {
  const tobedeliverydate = calculatePickupDate(dropOffDate);

  return (
    <div className="job-info-box">
      <div className="head-box flex-box space-between align-center">
        <h6 className="tab-detail-heading f-20">
          {" "}
          <span>
            <img src={jobInfoIcon} alt="" />
          </span>{" "}
          Job Details
        </h6>
      </div>
      <ul className="job-info-listing flex-box space-between">
        <li>
          <div className="text-box">
            <h6>ID</h6>
            <h4>{jobId}</h4>
          </div>
        </li>
        {/* <li>
                    <div className="text-box">
                        <h6>SKU </h6>
                        <h4>{SKU}</h4>
                    </div>
                </li> */}
        <li>
          <div className="text-box">
            <h6>Cateogary & Service</h6>
            <h4>{service}</h4>
          </div>
        </li>
        <li>
          <div className="text-box">
            <h6>Order created on </h6>
            <h4>
              {new Date(createdAt).toLocaleString("en-US", {
                day: "numeric",
                month: "long",
                weekday: "long",
              })}
            </h4>
          </div>
        </li>
        <li>
          <div className="text-box">
            <h6>Fixer Assign Date </h6>
            <h4>
              {new Date(pickUpDate).toLocaleString("en-US", {
                day: "numeric",
                month: "long",
                weekday: "long",
              })}
            </h4>
          </div>
        </li>
        <li>
          <div className="text-box">
            <h6 className="relative">
              Estimated Completion Date <span className="status-dot red"></span>
            </h6>
            <h4>
              {new Date(tobedeliverydate).toLocaleString("en-US", {
                day: "numeric",
                month: "long",
                weekday: "long",
              })}
            </h4>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default JobInfo;
