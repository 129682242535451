import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ctaArrow from "../../assets/img/cta-arrow.webp";
import popupCloseIcon from "../../assets/img/popup-close-icon.webp";
import popupMailIcon from "../../assets/img/popup-mail-icon.webp";
import useUserStore from "../../store/userStore";
import api from "../../store/api";
import { useQuery } from "react-query";

function PayoutTab({ title, value }) {
  return (
    <div className="job-list-inner-box">
      <h6>{title}</h6>
      <h4>{value}</h4>
    </div>
  );
}

function Payout({ payout }) {
  const {
    orderDate,
    completedOn,
    orderIdSku,
    serviceName,
    amount,
    payoutCreditedOn,
    itemName,
    paymentMode,
  } = payout;
  console.log(payout)
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  
  return (
    <>
      <li>
        <Link
          onClick={openPopup}
          className="job-box flex-box align-center space-between"
        >
          <PayoutTab title="Order Date" value={new Date(payout?.createdAt).toLocaleDateString()} />
          <PayoutTab title="Order ID" value={payout?.order_id_custom} />
          <PayoutTab title="Service Name" value={payout?.service_details?.name} />
          <PayoutTab title="Amount" value={payout?.service_details?.standard_price} />
          <PayoutTab title="Payout Credited On" value={new Date(payout?.updatedAt).toLocaleDateString()} />
          <div className="order-cta-box">
            <img src={ctaArrow} alt="" />
          </div>
        </Link>
        {showPopup && (
          <div className="popup-outer-box">
            <div className="popup-box" style={{width : "40%"}}>
              <button className="popup-close-btn" onClick={closePopup}>
                <img src={popupCloseIcon} alt="" />
              </button>
              <h4 className="popup-inner-heading">Received For </h4>
              <div className="popup-inner-list-box flex-box align-center space-between">
                <div className="field-box">
                  <h6>Order ID & SKU</h6>
                  <h4>{payout?.order_id_custom}</h4>
                </div>
                <div className="field-box">
                  <h6>Order Date</h6>
                  <h4>{new Date(payout?.createdAt).toLocaleDateString()}</h4>
                </div>
                <div className="field-box">
                  <h6>Completed On</h6>
                  <h4>{new Date(payout?.createdAt).toLocaleDateString()}</h4>
                </div>
              </div>
              <div className="field-box border-bottom">
                <h6>Service Name</h6>
                <h4>{payout?.service_details?.name}</h4>
              </div>
              <div className="field-box">
                <h6>Items Name</h6>
                <h4>{payout?.service_details?.items[0]?.name}</h4>
              </div>
              <div className="popup-payout-list-box">
                <h4 className="popup-inner-heading">Payout Details</h4>
                <div className="flex-box space-between align-center">
                  <div className="field-box">
                    <h6>Payout</h6>
                    <h4>{payout?.service_details?.standard_price}</h4>
                  </div>
                  <div className="field-box">
                    <h6>Credited On</h6>
                    <h4>{new Date(payout?.updatedAt).toLocaleDateString()}</h4>
                  </div>
                  <div className="field-box">
                    <h6>Payment Mode</h6>
                    <h4>{"Online"}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </li>
    </>
  );
}

function AllCreditsTab() {
  const { user } = useUserStore();
  const location = useLocation();
  const fetchAllOrderForASingleFixer = async () => {
    const response = await api.get(`order/getalloffixer/${user?._id}`);
    return response.data;
  };
  const [subtotal,setSubTotal] = useState(null)
  const [allPaidData,setAllPaidData] = useState(null)
  const { data, isLoading } = useQuery({
    queryFn: fetchAllOrderForASingleFixer,
    queryKey: ["fetchAllOrderForASingleFixer"],
    onSuccess : (response) => {
      if(location.pathname == "/credited"){
        let allPaidData = response.filter((itm) => itm?.is_paid == "Paid" && itm?.is_paid != undefined)
        setAllPaidData(allPaidData)
        const total = allPaidData.map((item,index) => {
          return item?.item_details?.pricing_plan == "Standard" ? item?.service_details?.standard_price : item?.service_details?.premium_price
        });
        setSubTotal(total);
      }
      if(location.pathname == "/pending"){
        let allPaidData = response.filter((itm) => itm?.is_paid != "Paid" || itm?.is_paid != undefined)
        setAllPaidData(allPaidData)
        const total = allPaidData.map((item,index) => {
          return item?.item_details?.pricing_plan == "Standard" ? item?.service_details?.standard_price : item?.service_details?.premium_price
        });
        setSubTotal(total);
      }
    },
    refetchInterval : 1000
  });

  return (
    <div className="tab-detail-outer-box">
      <div className="payout-head-box flex-box space-between align-center">
        <h6 className="tab-detail-heading margin-top-zero"> My Payouts </h6>
        <div className="right-box flex-box align-center">
          <h4>Export CSV</h4>
          <select>
            <option value="All Time">All Time</option>
            <option value="This Month">This Month</option>
            <option value="Past 3 Month">Past 3 Month</option>
          </select>
        </div>
      </div>
      <div className="payouts-lifetime-data-box flex-box">
        <div className="lifetime-data">
          <h6 className="right">Lifetime Data</h6>
          <h4>${subtotal?.reduce((acc, currentValue) => acc + Number(currentValue), 0)}</h4>
          <h6>Total Payouts</h6>
        </div>
        <div className="lifetime-data">
          <h6 className="right">Lifetime Data</h6>
          <h4>{allPaidData?.length}</h4>
          <h6>Order Completed</h6>
        </div>
      </div>
      {!isLoading && allPaidData != null && (
        <ul className="all-payouts-listing all-jobs-listing">
          {allPaidData?.map((payout, index) => (
            <Payout key={index} payout={payout} />
          ))}
        </ul>
      )}
    </div>
  );
}

export default AllCreditsTab;
