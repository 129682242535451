import create from 'zustand';
const useUserStore = create((set) => ({
    isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
    user: JSON.parse(localStorage.getItem('user') || '{}'),
    login: () => set({ isLoggedIn: true }),
    logout: () => {
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('user');
        set({
            isLoggedIn: false,
            // @ts-ignore
            user: {}
        });
    },
    updateUser: (newUserData) => {
        localStorage.setItem('user', JSON.stringify(newUserData));
        set({ user: newUserData });
    },
}));

export default useUserStore;
