import axios from "axios";
const api = axios.create({
  //   baseURL: "https://backend.codxegy.com",
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Origin": "*",
  },
});
export default api;
