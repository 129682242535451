import React from "react";
import itemVideoPoster from "../../assets/img/video-poster.webp";
import itemImage from "../../assets/img/item-detail-img.webp";

function JobDetailBox({ order, title }) {
  return (
    <ul>
      <li className="item-detail-box">
        <div className="head-box flex-box space-between align-center">
          <h6 className="f-20 flex-box align-center">
            Item & Service :
            {
              <React.Fragment>
                {` ${order?.service_details?.name}`}
              </React.Fragment>
            }{" "}
          </h6>
        </div>
        <ul className={`item-detail-inner-box-listing flex-box`} style={{justifyContent : "space-around"}}>
          <li className="item-detail-inner-box flex-box space-between">
            <div className="text-box">
              <div className="field-box">
                <h6>Job Description</h6>
                <h4>{order?.item_details?.description}</h4>
              </div>

              {order?.service_details?.selectedService == "Alteration" && (
                <React.Fragment>
                  <div className="field-box">
                    <h6>Mode Of Measurements In Items</h6>
                    {/* <h4>{order?.service_details?.alterationDetails?.type}</h4> */}
                    <h4>
                      {order?.service_details?.alterationDetails?.measurement}
                    </h4>
                  </div>
                </React.Fragment>
              )}
              {order?.service_details?.selectedService == "Repairing" && (
                <React.Fragment>
                  <div className="field-box">
                    <h4>
                      {" "}
                      Repair Location :{" "}
                      {order?.service_details?.repair_details?.location}
                    </h4>
                  </div>
                  <div className="field-box">
                    <h4>
                      {" "}
                      Repair Description :{" "}
                      {order?.service_details?.repair_details?.description}{" "}
                    </h4>
                  </div>
                </React.Fragment>
              )}
            </div>
            {/* <div className="img-box" style={{ marginTop: "1rem" }}>
              <h6>Supporting Images & Videos</h6>
              <div className="inner-img-box flex-box space-between">
                <video poster={itemVideoPoster} src=""></video>
                <img src={itemImage} alt="" />
              </div>
            </div> */}
          </li>
          <li className="item-detail-inner-box flex-box space-between">
            <div className="text-box">
              {order?.service_details?.item_notes != "" && order?.service_details?.item_notes != undefined &&
                <div className="field-box">
                  <h6>Notes for Tailor</h6>
                  <h4>{order?.service_details?.item_notes}</h4>
                </div>
              }
              {order?.status != undefined &&
                <div className="field-box">
                  <h6>Order Status</h6>
                  <h4>{order?.status == "Open" ? "Assigned" : order?.status}</h4>
                </div>
              }
              {/* <div className="field-box">
                <h6>Service Category</h6>
                <h4>{order?.service_details?.selectedService} -{" "}
                  {order?.item_details?.pricing_plan == "Standard"
                    ? "Standard"
                    : "Premium"}</h4>
              </div> */}
            </div>
          </li>
          <li className="item-detail-inner-box flex-box space-between">
            <div className="text-box">
              <div className="field-box">
                <h6>Item</h6>
                <h4>{order?.item_details?.name}</h4>
              </div>
              <div className="field-box">
                <h6>Service Name</h6>
                <h4>{order?.service_details?.name}</h4>
              </div>
              {order?.fixer_details?.additional_info != "" &&
                  <div className="field-box">
                  <h6>Admin Notes</h6>
                  <h4>{order?.fixer_details?.additional_info}</h4>
                </div>
              }
            </div>
          </li>
        </ul>
      </li>
    </ul>
  );
}

export default JobDetailBox;
