import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ordersIcon from "../../assets/img/menu-icon-1.webp";
import customersIcon from "../../assets/img/menu-icon-2.webp";
import editIcon from "../../assets/img/customer-edit-icon.png";
import customerImage from "../../assets/img/customer-img.webp";
import telIcon from "../../assets/img/tel-icon.webp";
import mailIcon from "../../assets/img/email-icon.webp";
import locationIcon from "../../assets/img/location-icon.webp";
import popupCloseIcon from "../../assets/img/popup-close-icon.webp";
import defaultImg from "../../assets/img/empty-img.webp";
import changeImg from "../../assets/img/user-change-img.webp";
import useUserStore from "../../store/userStore";

function MenuBox() {
  const { user } = useUserStore();
  const navigate = useNavigate();
  const location = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const [selectedInnerTab, setSelectedInnerTab] = useState("assigned");
  const [selectedInnerTab2, setSelectedInnerTab2] = useState("credited");
  function removeSpecialCharacters(str) {
    const decodedStr = decodeURIComponent(str);
    return decodedStr.replace(/[^\w\s]/gi, '').toLowerCase().replace(" ", "");
  }
  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const tab = pathSegments[1];
    const tabs1 = pathSegments[1];
    let finaltab = removeSpecialCharacters(tabs1)

    if (finaltab && (finaltab === "assigned" || finaltab === "itemreceivedpicked" || finaltab === "inprogress" || finaltab === "fixedbutnot dropped" || finaltab === "fixeddropped completed" || finaltab === "needmoreinfo" || finaltab === "onholdor dispute" || finaltab === "wascancelledby admin")) {
      setSelectedTab("dashboard");
      setSelectedInnerTab(finaltab);
    }
    if (tab && (tab === "cancelbyadmin" || tab === "onhold" || tab === "needmore" || tab === "fixed" || tab === "fixednotdropped" || tab === "assigned" || tab === "itempicked" || tab === "inprogress")) {
      setSelectedTab("dashboard");
      setSelectedInnerTab(tab);
    }
    if (tab && (tab === "credited" || tab === "pending")) {
      setSelectedTab(tab);
      setSelectedInnerTab2(tab);
    }
  }, [location.pathname]);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    navigate(`/${tab}`, { state: {} });
  };

  const handleInnerTabClick = (tab) => {
    setSelectedInnerTab(tab);
    navigate(`/${tab}`, { state: {} });
  };

  const handleInnerTab2Click = (tab) => {
    setSelectedInnerTab2(tab);
    navigate(`/${tab}`, { state: {} });
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className="main-menu-box">
        <div className="top-box">
          <div className="customer-detail-box">
            <div className="customer-name-box flex-box align-center">
              <div className="img-box">
                <div className="img">{user?.name.substr(0, 1)}</div>
              </div>
              <div className="text-box">
                <h6>FXRY TAILOR</h6>
                <h4>{user?.name}</h4>
              </div>
            </div>
            <div className="customer-contact-detail-box">
              <h4><span><img src={telIcon} alt="" /></span>{user?.phone}</h4>
              <h4><span><img src={mailIcon} alt="" /></span>{user?.email}</h4>
              <h4><span><img src={locationIcon} alt="" /></span>{user?.address}</h4>
            </div>
          </div>
          <h6 className="menu-sub-text">MAIN</h6>
          <ul className="menu-listing">
            <li className="order-main-tab">
              <div
                className={`tab-box ${selectedTab === "dashboard" ? "selected" : ""}`}
                onClick={() => {
                  handleMenuOpen();
                  handleTabClick("dashboard");
                }}
              >
                <h6>
                  <span>
                    <img src={ordersIcon} alt="" />
                  </span>
                  Jobs
                </h6>
              </div>
              <div className={`menu-inner-listing ${isMenuOpen ? "" : "full-height"}`}>
                <div
                  className={`menu-inner-tab-box ${selectedInnerTab === "assigned" || selectedInnerTab === "assigned" ? "selected" : ""}`}
                  onClick={() => handleInnerTabClick("assigned")}
                >
                  <h6>Assigned</h6>
                </div>
                <div
                  className={`menu-inner-tab-box ${selectedInnerTab === "itempicked" || selectedInnerTab === "itemreceivedpicked" ? "selected" : ""}`}
                  onClick={() => handleInnerTabClick("itempicked")}
                >
                  <h6>Item Received/Picked</h6>
                </div>
                <div
                  className={`menu-inner-tab-box ${selectedInnerTab === "inprogress" || selectedInnerTab === "inprogress" ? "selected" : ""}`}
                  onClick={() => handleInnerTabClick("inprogress")}
                >
                  <h6>In Progress</h6>
                </div>
                <div
                  className={`menu-inner-tab-box ${selectedInnerTab === "fixednotdropped" || selectedInnerTab === "fixedbutnot dropped" ? "selected" : ""}`}
                  onClick={() => handleInnerTabClick("fixednotdropped")}
                >
                  <h6>Fixed But Not Dropped</h6>
                </div>
                <div
                  className={`menu-inner-tab-box ${selectedInnerTab === "fixed" || selectedInnerTab === "fixeddropped completed" ? "selected" : ""}`}
                  onClick={() => handleInnerTabClick("fixed")}
                >
                  <h6>Fixed, Dropped & Completed</h6>
                </div>
                <div
                  className={`menu-inner-tab-box ${selectedInnerTab === "needmore" || selectedInnerTab === "needmoreinfo" ? "selected" : ""}`}
                  onClick={() => handleInnerTabClick("needmore")}
                >
                  <h6>Need More Info</h6>
                </div>
                <div
                  className={`menu-inner-tab-box ${selectedInnerTab === "onhold" || selectedInnerTab === "onholdor dispute" ? "selected" : ""}`}
                  onClick={() => handleInnerTabClick("onhold")}
                >
                  <h6>On Hold or Dispute</h6>
                </div>
                <div
                  className={`menu-inner-tab-box ${selectedInnerTab === "cancelbyadmin" || selectedInnerTab === "wascancelledby admin" ? "selected" : ""}`}
                  onClick={() => handleInnerTabClick("cancelbyadmin")}
                >
                  <h6>Was Cancelled By Admin</h6>
                </div>
              </div>
            </li>
            <li>
              <div
                className={`tab-box ${isMenuOpen ? "" : selectedTab === "pending" || selectedTab === "credited" ? "grey-bg" : "hide"}`}
                onClick={() => {
                  handleTabClick("credited");
                }}
              >
                <h6>
                  <span>
                    <img src={customersIcon} alt="" />
                  </span>
                  Payouts
                </h6>
              </div>
              <div className={`menu-inner-listing ${isMenuOpen ? "" : "full-height"}`}>
                <div
                  className={`menu-inner-tab-box ${selectedInnerTab2 === "credited" ? "selected" : ""}`}
                  onClick={() => handleInnerTab2Click("credited")}
                >
                  <h6>Credited</h6>
                </div>
                <div
                  className={`menu-inner-tab-box ${selectedInnerTab2 === "pending" ? "selected" : ""}`}
                  onClick={() => handleInnerTab2Click("pending")}
                >
                  <h6>Pending</h6>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="bottom-box">
          <h3 className="logout-btn">Logout</h3>
        </div>
      </div>
    </>
  );
}

export default MenuBox;
