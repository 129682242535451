import React from 'react';
import { Link } from 'react-router-dom';
import ctaArrow from '../../assets/img/cta-arrow.webp';

const calculatePickupDate = (deliveryDate) => {
    const deliveryDay = new Date(deliveryDate).getDay(); // 0 represents Sunday, 1 represents Monday, 3 represents Wednesday, 5 represents Friday
    let pickupOffset = 0;

    switch (deliveryDay) {
        case 1: // Monday
            pickupOffset = 10;
            break;
        case 3: // Wednesday
            pickupOffset = 12;
            break;
        case 5: // Friday
            pickupOffset = 10;
            break;
        default:
            return null; // Handle other days if necessary
    }
  
    const pickupDate = new Date(deliveryDate);
  
    while (pickupOffset > 0) {
        pickupDate.setDate(pickupDate.getDate() + 1);
        const dayOfWeek = pickupDate.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
            pickupOffset--;
        }
    }
  
    return pickupDate.toISOString();
  };


function JobTab({ title, value }) {
    return (
        <div className="job-list-inner-box">
            <h6>{title}</h6>
            <h4>{value}</h4>
        </div>
    );
}
function removeSpecialCharacters(str) {
    const decodedStr = decodeURIComponent(str);
    return decodedStr.replace(/[^\w\s]/gi, '').toLowerCase().replace(" ", "");
  }
function AllJobTab({ data }) {
    return (
        <div className="tab-detail-outer-box">
            <h6 className='tab-detail-heading'> Current Job Openings <span>Count: {data?.length}</span></h6>
            <ul className='all-jobs-listing'>
                {data?.reverse()?.map((order, index) => (
                    <>
                        <li>
                            <Link
                                to={{
                                    pathname: `/${removeSpecialCharacters(order?.status)}/jobs/${order?._id}`
                                }}
                                className='job-box flex-box align-center space-between flex-wrap'>
                                <JobTab title="ID" value={order?.order_id_custom} />
                                <JobTab title="Item-Task Name" value={order?.service_details?.name} />
                                <JobTab title="Pick Up Date" value={new Date(order?.orders[0]?.collection_date).toLocaleString('en-US', { day: 'numeric', month: 'long', weekday: 'long' })} />
                                <JobTab title="Drop Off Date" value={new Date(calculatePickupDate(order?.orders[0]?.collection_date)).toLocaleString('en-US', { day: 'numeric', month: 'long', weekday: 'long' })} />
                                <JobTab title="Customer Name" value={order?.orders[0]?.customer_details?.name} />
                                <JobTab title="Status" value={order?.status == "Open" ? "Assigned" : order?.status} />
                                <div className="order-cta-box">
                                    <img src={ctaArrow} alt="" />
                                </div>
                            </Link>
                        </li>
                    </>
                ))}
            </ul>
        </div>
    );
}

export default AllJobTab;
