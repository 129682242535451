import React, { useEffect, useState } from "react";
import JobInfo from "../jobs/JobInfo";
import JobDetailBox from "../jobs/JobDetailBox";
import toast from "react-hot-toast";
import api from "../../store/api";
import { useNavigate, useLocation } from "react-router-dom";
const calculatePickupDate = (deliveryDate) => {
  const deliveryDay = new Date(deliveryDate).getDay(); // 0 represents Sunday, 1 represents Monday, 3 represents Wednesday, 5 represents Friday
  let pickupOffset = 0;

  // Determine the initial offset based on the delivery day
  switch (deliveryDay) {
      case 1: // Monday
          pickupOffset = 10;
          break;
      case 3: // Wednesday
          pickupOffset = 12;
          break;
      case 5: // Friday
          pickupOffset = 10;
          break;
      default:
          return null; // Handle other days if necessary
  }

  const pickupDate = new Date(deliveryDate);

  // Add offset to delivery date, skipping Saturdays and Sundays
  while (pickupOffset > 0) {
      pickupDate.setDate(pickupDate.getDate() + 1);
      const dayOfWeek = pickupDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Skip Sundays (0) and Saturdays (6)
          pickupOffset--;
      }
  }

  return pickupDate.toISOString(); // Convert to ISO format for consistency
};

function JobDetailTab({
  jobId,
  taskName,
  pickUpDate,
  dropOffDate,
  order,
  refetch,
}) {
  const navigate = useNavigate()
  const tobelivereddate = calculatePickupDate(order?.orders[0]?.collection_date);
  // const tobelivereddate =  calculatePickupDate(order?.orders[0]?.deliveryDate);
  const [leftdays, setleftdays] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [progressStatus, setProgressStatus] = useState();
  useEffect(() => {
    // setProgressStatus("Open")
    let currentDate = new Date();
    let targetDate = new Date(tobelivereddate);
    let difference = targetDate - currentDate;
    let daysLeft = Math.floor(difference / (1000 * 60 * 60 * 24));
    setleftdays(daysLeft > 0 ? daysLeft : 0);
  }, [tobelivereddate]);

  useEffect(() => {
    setProgressStatus(order?.status || "Assigned")
  },[order])
  
  const [isItemsPicked, setIsItemsPicked] = useState(order?.pickup_status || "No");
  // Function to handle status change
  const handleStatusChange = async (type) => {
    // Here you can submit the status to your backend or perform any other action
    if (type == "pickup") {
      const res = await api.post(`order/isitempicked/${order?._id}`, {
        status: isItemsPicked,
      });
      toast.success("Updated Pickup Details");
      refetch();
    } else {
      const res = await api.post(`order/isordercompleted/${order?._id}`, {
        status: progressStatus,
      });
      toast.success("Updated Job Status");
      refetch();
      if(progressStatus == "Assigned"){
        navigate("/assigned")
      }
      if(progressStatus == "Item Received/Picked"){
        navigate("/itempicked")
      }
      if(progressStatus == "In Progress"){
        navigate("/inprogress")
      }
      if(progressStatus == "Fixed But Not Dropped"){
        navigate("/fixednotdropped")
      }
      if(progressStatus == "Fixed, Dropped & Completed"){
        navigate("/fixed")
      }
      if(progressStatus == "Need More Info"){
        navigate("/needmore")
      }
      if(progressStatus == "On Hold or Dispute"){
        navigate("/onhold")
      }
      if(progressStatus == "Was Cancelled By Admin"){
        navigate("/cancelbyadmin")
      }
    }
  };

  const statuses = [
    { label : "Assigned", value : "Assigned" },
    { label : "Item Received/Picked", value : "Item Received/Picked" },
    { label : "In Progress", value : "In Progress" },
    { label : "Fixed But Not Dropped", value : "Fixed But Not Dropped" },
    { label : "Fixed, Dropped & Completed", value : "Fixed, Dropped & Completed" },
    { label : "Need More Info", value : "Need More Info" },
    { label : "On Hold or Dispute", value : "On Hold or Dispute" },
    { label : "Was Cancelled By Admin", value : "Was Cancelled By Admin" },
  ]

  return (
    <div className="job-detail-outer-box flex-box space-between">
      <div className="job-detail-box">
        <JobInfo
          service={
            order?.item_details?.pricing_plan +
            "," +
            order?.service_details?.selectedService
          }
          jobId={order?.order_id_custom}
          collectionDate={order?.collection_date}
          taskName={`${order?.service_details?.name}`}
          pickUpDate={order?.orders[0]?.fixer_assigned_at || order?.orders[0]?.collection_date}
          createdAt={order?.createdAt}
          dropOffDate={order?.orders[0]?.deliveryDate}
        />
        <JobDetailBox order={order} />
      </div>
      <div className="job-side-detail-box">
        <div className="job-day-left-box">
          <h2>{leftdays}</h2>
          <h4>Days Left</h4>
          {order?.status != "Completed" &&
              <>
                <h6>MARK AS</h6>
          <select
            value={progressStatus}
            onChange={(e) => {
              setIsChanged(true)
              setProgressStatus(e.target.value)
            }}
          >
            {statuses?.map((optiondata, index) => (
              <option key={index} value={optiondata.value} selected={progressStatus == optiondata.value ? true : false}>{optiondata.label}</option>
            ))}
          </select>
              </>
          }
          <h6 style={{marginTop : "0.5rem", marginBottom : "1rem"}}>{order?.status} - {new Date(order?.job_status_updated_at).toLocaleDateString("en-US", {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}</h6>
          {isChanged &&
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleStatusChange("status")}
            >
              save
            </span>
          }
          {progressStatus == "Completed" &&
            <h6>Completed</h6>
          }
        </div>
      </div>
    </div>
  );
}

export default JobDetailTab;
