import React from "react";
import MenuBox from "../components/common/MenuBox";
import AllJobTab from "../components/module-tabs/AllJobTab";
import api from "../store/api";
import useUserStore from "../store/userStore";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

function DashBoard() {
  const { user } = useUserStore();
  const fetchAllOrderForASingleFixer = async () => {
    const response = await api.get(`order/getalloffixer/${user?._id}`);
    return response.data;
  };

  const { data, isLoading } = useQuery({
    queryFn: fetchAllOrderForASingleFixer,
    queryKey: ["fetchAllOrderForASingleFixer"],
  });

  let orders;
  const location = useLocation();
  if (location.pathname === "/dashboard" || location.pathname === "/assigned") {
    orders = data?.filter((orderdata) => orderdata.status == "Assigned" || orderdata.status == "Open");
  }
  if (location.pathname === "/cancelbyadmin") {
    orders = data?.filter((data) => data.status == "Was Cancelled By Admin");
  }
  if (location.pathname === "/onhold") {
    orders = data?.filter((data) => data.status == "On Hold or Dispute");
  }
  if (location.pathname === "/needmore") {
    orders = data?.filter((data) => data.status == "Need More Info");
  }
  if (location.pathname === "/fixed") {
    orders = data?.filter((data) => data.status == "Fixed, Dropped & Completed");
  }
  if (location.pathname === "/fixednotdropped") {
    orders = data?.filter((data) => data.status == "Fixed But Not Dropped");
  }
  if (location.pathname === "/inprogress") {
    orders = data?.filter((data) => data.status == "In Progress");
  }
  if (location.pathname === "/itempicked") {
    orders = data?.filter((data) => data.status == "Item Received/Picked");
  }

  return (
    <section className="main-dashboard-section">
      <div className="container">
        <div className="main-dashboard-box flex-box space-between">
          <MenuBox />
          {!isLoading && <AllJobTab data={orders} />}
        </div>
      </div>
    </section>
  );
}

export default DashBoard;
